import { default as add_45billing_45methodYYAHizhaxcMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/add-billing-method.vue?macro=true";
import { default as amex2q8MddrG6AMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/amex.vue?macro=true";
import { default as defaultXUDmPEq4wzMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/default.vue?macro=true";
import { default as mastercardkSNALiAfW6Meta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/mastercard.vue?macro=true";
import { default as visaoAfSdaT6v9Meta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/visa.vue?macro=true";
import { default as use_45add_45payment_45method_45validationmhMJqQzwusMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/composables/use-add-payment-method-validation.ts?macro=true";
import { default as pagehsoGMhirNkMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/page.vue?macro=true";
import { default as login_45formXi9bEhlHiKMeta } from "/app/apps/nuxt-front/pages/login/components/login-form.vue?macro=true";
import { default as use_45login_45validationnF7LowXB7YMeta } from "/app/apps/nuxt-front/pages/login/composables/use-login-validation.ts?macro=true";
import { default as pagexTyZRA7Ex1Meta } from "/app/apps/nuxt-front/pages/login/page.vue?macro=true";
import { default as pageAlMIiG74AAMeta } from "/app/apps/nuxt-front/pages/not-found/page.vue?macro=true";
import { default as add_45order_45producttWhbPjNUzoMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order-product.vue?macro=true";
import { default as add_45order_45productsZ6vTlTgqiXMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order-products.vue?macro=true";
import { default as add_45order_45stepsvm8x3gHTodMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order-steps.vue?macro=true";
import { default as add_45orderJ0IIsrsOSvMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order.vue?macro=true";
import { default as add_45order_45first_45stepmr0MWb2E33Meta } from "/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-first-step.vue?macro=true";
import { default as add_45order_45second_45stepN0POmKcOz0Meta } from "/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-second-step.vue?macro=true";
import { default as add_45order_45third_45stepdxRi5yTIKNMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-third-step.vue?macro=true";
import { default as index5GDZUU8dsFMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/types/index.ts?macro=true";
import { default as bulk_45dndsyg7mp2yzRMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-dnd.vue?macro=true";
import { default as bulk_45ordersGHM6u9keSqMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-orders.vue?macro=true";
import { default as bulk_45uploadKu8ldf5jesMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-upload.vue?macro=true";
import { default as fileliqoRPRoH3Meta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/file.vue?macro=true";
import { default as order_45designszBccjX4VvxMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/order-designs.vue?macro=true";
import { default as product_45variant1flZmd3CMzMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/product-variant.vue?macro=true";
import { default as order_45files_45colhgwE21Iww0Meta } from "/app/apps/nuxt-front/pages/orders/components/columns/order-files-col.vue?macro=true";
import { default as order_45detail_45address_45street3YftxFtIOTMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-address-street.vue?macro=true";
import { default as order_45detail_45view_45addresse58nmVic8mMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-view-address.vue?macro=true";
import { default as orders_45detail_45viewk1w2u0dkF4Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/orders-detail-view.vue?macro=true";
import { default as detail_45pricelzizwy7ZCWMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/price/detail-price.vue?macro=true";
import { default as detail_45add_45product_45cardpYRCWskJgeMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product-card.vue?macro=true";
import { default as detail_45add_45producte2pbZB88b8Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product.vue?macro=true";
import { default as detail_45product_45bodyMSN1ixS2N3Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-body.vue?macro=true";
import { default as detail_45product_45cardbdpj7ooIBFMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-card.vue?macro=true";
import { default as detail_45product_45headerVBSy6M2HZFMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-header.vue?macro=true";
import { default as detail_45product_45invalid_45bodykUn6K40wfnMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-invalid-body.vue?macro=true";
import { default as detail_45productsVlsFFQq8VSMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-products.vue?macro=true";
import { default as order_45detail_45product_45filensrU6EZzQ2Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-file.vue?macro=true";
import { default as order_45detail_45product_45filescAS303gjeQMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-files.vue?macro=true";
import { default as order_45detail_45product_45uploaduWSzGhlmOPMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-upload.vue?macro=true";
import { default as detail_45product_45upload_45erroruzH5N70usyMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-error.vue?macro=true";
import { default as detail_45product_45upload_45idlejXCw4CUXg2Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-idle.vue?macro=true";
import { default as detail_45product_45upload_45replace3IkZSECFGMMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-replace.vue?macro=true";
import { default as detail_45product_45upload_45uploaded312wz78srvMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-uploaded.vue?macro=true";
import { default as detail_45product_45upload_45uploadingZVrwHRUOD2Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-uploading.vue?macro=true";
import { default as detail_45product_45personalization_45editUN9pYLD67lMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/detail-product-personalization-edit.vue?macro=true";
import { default as order_45detail_45product_45personalizationwYC5St9kRuMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/order-detail-product-personalization.vue?macro=true";
import { default as detail_45enter_45sku75EBqDtmtzMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/sku/detail-enter-sku.vue?macro=true";
import { default as order_45detail_45view_45tracking_45number_45blockjrQVmmLCqHMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number-block.vue?macro=true";
import { default as order_45detail_45view_45tracking_45numberoXeCxhdTauMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number.vue?macro=true";
import { default as orders_45data_45table8IDRYR79J6Meta } from "/app/apps/nuxt-front/pages/orders/components/orders-data-table.vue?macro=true";
import { default as orders_45status_45filterKotXkhCAPbMeta } from "/app/apps/nuxt-front/pages/orders/components/orders-status-filter.vue?macro=true";
import { default as orders_45personalization_45bulk_45colFsRkbwSBQCMeta } from "/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-bulk-col.vue?macro=true";
import { default as orders_45personalization_45data_45tablevDKyrXnbzuMeta } from "/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-data-table.vue?macro=true";
import { default as orders_45personalization_45text_45cols9WjZ9qpAuMeta } from "/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-text-col.vue?macro=true";
import { default as orders_45personalization_45uploadZSFuBYVgjMMeta } from "/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-upload.vue?macro=true";
import { default as use_45add_45order_45validationgbQqZxhh2zMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-add-order-validation.ts?macro=true";
import { default as use_45design_45uploadHoShVmST1xMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-design-upload.ts?macro=true";
import { default as use_45detail_45view_45do_45paymentDTS6U7OQdtMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-detail-view-do-payment.ts?macro=true";
import { default as use_45detail_45view_45editing_45addressn7CQj61WDjMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-detail-view-editing-address.ts?macro=true";
import { default as use_45detail_45view_45enter_45sku7CyFzkBl4eMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-detail-view-enter-sku.ts?macro=true";
import { default as use_45detail_45view_45update_45shipping_45addressHDHoPq94SuMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-detail-view-update-shipping-address.ts?macro=true";
import { default as use_45order_45detail_45view_45pageOnEs0VWlNMMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-order-detail-view-page.ts?macro=true";
import { default as use_45orders_45apik7IImllQ64Meta } from "/app/apps/nuxt-front/pages/orders/composables/use-orders-api.ts?macro=true";
import { default as use_45orders_45query_45stringgstqfwcrljMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-orders-query-string.ts?macro=true";
import { default as use_45product_45by_45sku_45for_45correctionwRLE7mpuqSMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-product-by-sku-for-correction.ts?macro=true";
import { default as is_45order_45product_45disabledNBaFVXeyvTMeta } from "/app/apps/nuxt-front/pages/orders/helpers/is-order-product-disabled.ts?macro=true";
import { default as is_45order_45shipping_45address_45editableKbf01pEu6cMeta } from "/app/apps/nuxt-front/pages/orders/helpers/is-order-shipping-address-editable.ts?macro=true";
import { default as pageQgZ0rfUofaMeta } from "/app/apps/nuxt-front/pages/orders/page.vue?macro=true";
import { default as has_45active_45payment_45method7bfx4ZwawEMeta } from "/app/apps/nuxt-front/pages/orders/state/has-active-payment-method.ts?macro=true";
import { default as ordersnG8LMhzIw8Meta } from "/app/apps/nuxt-front/pages/orders/types/orders.ts?macro=true";
import { default as product_45breadcrumbstqdTDGvCumMeta } from "/app/apps/nuxt-front/pages/product/components/product-breadcrumbs.vue?macro=true";
import { default as product_45infoYGRuDB6nbEMeta } from "/app/apps/nuxt-front/pages/product/components/product-info.vue?macro=true";
import { default as product_45mockup_45stylesMR9E4SXeFGMeta } from "/app/apps/nuxt-front/pages/product/components/product-mockup-styles.vue?macro=true";
import { default as use_45product_45apitVDEMnKyncMeta } from "/app/apps/nuxt-front/pages/product/composables/use-product-api.ts?macro=true";
import { default as page414Pc5Ll8fMeta } from "/app/apps/nuxt-front/pages/product/page.vue?macro=true";
import { default as category_45link7lCkmiQWpGMeta } from "/app/apps/nuxt-front/pages/products/components/category-link.vue?macro=true";
import { default as use_45categories_45apiN20x3TPXxYMeta } from "/app/apps/nuxt-front/pages/products/composables/use-categories-api.ts?macro=true";
import { default as use_45products_45api9qJQhu7uaHMeta } from "/app/apps/nuxt-front/pages/products/composables/use-products-api.ts?macro=true";
import { default as pagehVwMlxwkU5Meta } from "/app/apps/nuxt-front/pages/products/page.vue?macro=true";
import { default as registration_45stageswilsaZHpksMeta } from "/app/apps/nuxt-front/pages/registration/components/registration-stages.vue?macro=true";
import { default as stage_45indicatoru84cGmhdv1Meta } from "/app/apps/nuxt-front/pages/registration/components/stage-indicator.vue?macro=true";
import { default as company_45registration_45stagejh3BHLIfWEMeta } from "/app/apps/nuxt-front/pages/registration/components/stages/company-registration-stage.vue?macro=true";
import { default as profile_45registration_45stagetbOrQPxHwcMeta } from "/app/apps/nuxt-front/pages/registration/components/stages/profile-registration-stage.vue?macro=true";
import { default as use_45registration_45validation2Y7RSqDSCcMeta } from "/app/apps/nuxt-front/pages/registration/composables/use-registration-validation.ts?macro=true";
import { default as page8F47zOZ4UVMeta } from "/app/apps/nuxt-front/pages/registration/page.vue?macro=true";
export default [
  {
    name: "billing-update-methods-components-add-billing-method",
    path: "/billing/update-methods/components/add-billing-method",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/add-billing-method.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-amex",
    path: "/billing/update-methods/components/card-icons/amex",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/amex.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-default",
    path: "/billing/update-methods/components/card-icons/default",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/default.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-mastercard",
    path: "/billing/update-methods/components/card-icons/mastercard",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/mastercard.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-visa",
    path: "/billing/update-methods/components/card-icons/visa",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/visa.vue")
  },
  {
    name: "billing-update-methods-composables-use-add-payment-method-validation",
    path: "/billing/update-methods/composables/use-add-payment-method-validation",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/composables/use-add-payment-method-validation.ts")
  },
  {
    name: "billing-update-methods-page",
    path: "/billing/update-methods/page",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/page.vue")
  },
  {
    name: "login-components-login-form",
    path: "/login/components/login-form",
    component: () => import("/app/apps/nuxt-front/pages/login/components/login-form.vue")
  },
  {
    name: "login-composables-use-login-validation",
    path: "/login/composables/use-login-validation",
    component: () => import("/app/apps/nuxt-front/pages/login/composables/use-login-validation.ts")
  },
  {
    name: "login-page",
    path: "/login/page",
    component: () => import("/app/apps/nuxt-front/pages/login/page.vue")
  },
  {
    name: "not-found-page",
    path: "/not-found/page",
    component: () => import("/app/apps/nuxt-front/pages/not-found/page.vue")
  },
  {
    name: "orders-components-add-order-add-order-product",
    path: "/orders/components/add-order/add-order-product",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order-product.vue")
  },
  {
    name: "orders-components-add-order-add-order-products",
    path: "/orders/components/add-order/add-order-products",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order-products.vue")
  },
  {
    name: "orders-components-add-order-add-order-steps",
    path: "/orders/components/add-order/add-order-steps",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order-steps.vue")
  },
  {
    name: "orders-components-add-order-add-order",
    path: "/orders/components/add-order/add-order",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order.vue")
  },
  {
    name: "orders-components-add-order-steps-add-order-first-step",
    path: "/orders/components/add-order/steps/add-order-first-step",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-first-step.vue")
  },
  {
    name: "orders-components-add-order-steps-add-order-second-step",
    path: "/orders/components/add-order/steps/add-order-second-step",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-second-step.vue")
  },
  {
    name: "orders-components-add-order-steps-add-order-third-step",
    path: "/orders/components/add-order/steps/add-order-third-step",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-third-step.vue")
  },
  {
    name: "orders-components-add-order-types",
    path: "/orders/components/add-order/types",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/types/index.ts")
  },
  {
    name: "orders-components-bulk-upload-bulk-dnd",
    path: "/orders/components/bulk-upload/bulk-dnd",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-dnd.vue")
  },
  {
    name: "orders-components-bulk-upload-bulk-orders",
    path: "/orders/components/bulk-upload/bulk-orders",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-orders.vue")
  },
  {
    name: "orders-components-bulk-upload-bulk-upload",
    path: "/orders/components/bulk-upload/bulk-upload",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-upload.vue")
  },
  {
    name: "orders-components-bulk-upload-file",
    path: "/orders/components/bulk-upload/file",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/file.vue")
  },
  {
    name: "orders-components-bulk-upload-order-designs",
    path: "/orders/components/bulk-upload/order-designs",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/order-designs.vue")
  },
  {
    name: "orders-components-bulk-upload-product-variant",
    path: "/orders/components/bulk-upload/product-variant",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/product-variant.vue")
  },
  {
    name: "orders-components-columns-order-files-col",
    path: "/orders/components/columns/order-files-col",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/columns/order-files-col.vue")
  },
  {
    name: "orders-components-detail-view-address-order-detail-address-street",
    path: "/orders/components/detail-view/address/order-detail-address-street",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-address-street.vue")
  },
  {
    name: "orders-components-detail-view-address-order-detail-view-address",
    path: "/orders/components/detail-view/address/order-detail-view-address",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-view-address.vue")
  },
  {
    name: "orders-components-detail-view-orders-detail-view",
    path: "/orders/components/detail-view/orders-detail-view",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/orders-detail-view.vue")
  },
  {
    name: "orders-components-detail-view-price-detail-price",
    path: "/orders/components/detail-view/price/detail-price",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/price/detail-price.vue")
  },
  {
    name: "orders-components-detail-view-products-add-product-detail-add-product-card",
    path: "/orders/components/detail-view/products/add-product/detail-add-product-card",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product-card.vue")
  },
  {
    name: "orders-components-detail-view-products-add-product-detail-add-product",
    path: "/orders/components/detail-view/products/add-product/detail-add-product",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-body",
    path: "/orders/components/detail-view/products/card/detail-product-body",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-body.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-card",
    path: "/orders/components/detail-view/products/card/detail-product-card",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-card.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-header",
    path: "/orders/components/detail-view/products/card/detail-product-header",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-header.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-invalid-body",
    path: "/orders/components/detail-view/products/card/detail-product-invalid-body",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-invalid-body.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-products",
    path: "/orders/components/detail-view/products/card/detail-products",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-products.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-file",
    path: "/orders/components/detail-view/products/file/order-detail-product-file",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-file.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-files",
    path: "/orders/components/detail-view/products/file/order-detail-product-files",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-files.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-upload",
    path: "/orders/components/detail-view/products/file/order-detail-product-upload",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-upload.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-detail-product-upload-error",
    path: "/orders/components/detail-view/products/file/upload/detail-product-upload-error",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-error.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-detail-product-upload-idle",
    path: "/orders/components/detail-view/products/file/upload/detail-product-upload-idle",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-idle.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-detail-product-upload-replace",
    path: "/orders/components/detail-view/products/file/upload/detail-product-upload-replace",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-replace.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-detail-product-upload-uploaded",
    path: "/orders/components/detail-view/products/file/upload/detail-product-upload-uploaded",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-uploaded.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-detail-product-upload-uploading",
    path: "/orders/components/detail-view/products/file/upload/detail-product-upload-uploading",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload/detail-product-upload-uploading.vue")
  },
  {
    name: "orders-components-detail-view-products-personalization-detail-product-personalization-edit",
    path: "/orders/components/detail-view/products/personalization/detail-product-personalization-edit",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/detail-product-personalization-edit.vue")
  },
  {
    name: "orders-components-detail-view-products-personalization-order-detail-product-personalization",
    path: "/orders/components/detail-view/products/personalization/order-detail-product-personalization",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/order-detail-product-personalization.vue")
  },
  {
    name: "orders-components-detail-view-products-sku-detail-enter-sku",
    path: "/orders/components/detail-view/products/sku/detail-enter-sku",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/sku/detail-enter-sku.vue")
  },
  {
    name: "orders-components-detail-view-tracking-number-order-detail-view-tracking-number-block",
    path: "/orders/components/detail-view/tracking-number/order-detail-view-tracking-number-block",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number-block.vue")
  },
  {
    name: "orders-components-detail-view-tracking-number-order-detail-view-tracking-number",
    path: "/orders/components/detail-view/tracking-number/order-detail-view-tracking-number",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number.vue")
  },
  {
    name: "orders-components-orders-data-table",
    path: "/orders/components/orders-data-table",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/orders-data-table.vue")
  },
  {
    name: "orders-components-orders-status-filter",
    path: "/orders/components/orders-status-filter",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/orders-status-filter.vue")
  },
  {
    name: "orders-components-personalization-orders-personalization-bulk-col",
    path: "/orders/components/personalization/orders-personalization-bulk-col",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-bulk-col.vue")
  },
  {
    name: "orders-components-personalization-orders-personalization-data-table",
    path: "/orders/components/personalization/orders-personalization-data-table",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-data-table.vue")
  },
  {
    name: "orders-components-personalization-orders-personalization-text-col",
    path: "/orders/components/personalization/orders-personalization-text-col",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-text-col.vue")
  },
  {
    name: "orders-components-personalization-orders-personalization-upload",
    path: "/orders/components/personalization/orders-personalization-upload",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/personalization/orders-personalization-upload.vue")
  },
  {
    name: "orders-composables-use-add-order-validation",
    path: "/orders/composables/use-add-order-validation",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-add-order-validation.ts")
  },
  {
    name: "orders-composables-use-design-upload",
    path: "/orders/composables/use-design-upload",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-design-upload.ts")
  },
  {
    name: "orders-composables-use-detail-view-do-payment",
    path: "/orders/composables/use-detail-view-do-payment",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-detail-view-do-payment.ts")
  },
  {
    name: "orders-composables-use-detail-view-editing-address",
    path: "/orders/composables/use-detail-view-editing-address",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-detail-view-editing-address.ts")
  },
  {
    name: "orders-composables-use-detail-view-enter-sku",
    path: "/orders/composables/use-detail-view-enter-sku",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-detail-view-enter-sku.ts")
  },
  {
    name: "orders-composables-use-detail-view-update-shipping-address",
    path: "/orders/composables/use-detail-view-update-shipping-address",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-detail-view-update-shipping-address.ts")
  },
  {
    name: "orders-composables-use-order-detail-view-page",
    path: "/orders/composables/use-order-detail-view-page",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-order-detail-view-page.ts")
  },
  {
    name: "orders-composables-use-orders-api",
    path: "/orders/composables/use-orders-api",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-orders-api.ts")
  },
  {
    name: "orders-composables-use-orders-query-string",
    path: "/orders/composables/use-orders-query-string",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-orders-query-string.ts")
  },
  {
    name: "orders-composables-use-product-by-sku-for-correction",
    path: "/orders/composables/use-product-by-sku-for-correction",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-product-by-sku-for-correction.ts")
  },
  {
    name: "orders-helpers-is-order-product-disabled",
    path: "/orders/helpers/is-order-product-disabled",
    component: () => import("/app/apps/nuxt-front/pages/orders/helpers/is-order-product-disabled.ts")
  },
  {
    name: "orders-helpers-is-order-shipping-address-editable",
    path: "/orders/helpers/is-order-shipping-address-editable",
    component: () => import("/app/apps/nuxt-front/pages/orders/helpers/is-order-shipping-address-editable.ts")
  },
  {
    name: "orders-page",
    path: "/orders/page",
    component: () => import("/app/apps/nuxt-front/pages/orders/page.vue")
  },
  {
    name: "orders-state-has-active-payment-method",
    path: "/orders/state/has-active-payment-method",
    component: () => import("/app/apps/nuxt-front/pages/orders/state/has-active-payment-method.ts")
  },
  {
    name: "orders-types-orders",
    path: "/orders/types/orders",
    component: () => import("/app/apps/nuxt-front/pages/orders/types/orders.ts")
  },
  {
    name: "product-components-product-breadcrumbs",
    path: "/product/components/product-breadcrumbs",
    component: () => import("/app/apps/nuxt-front/pages/product/components/product-breadcrumbs.vue")
  },
  {
    name: "product-components-product-info",
    path: "/product/components/product-info",
    component: () => import("/app/apps/nuxt-front/pages/product/components/product-info.vue")
  },
  {
    name: "product-components-product-mockup-styles",
    path: "/product/components/product-mockup-styles",
    component: () => import("/app/apps/nuxt-front/pages/product/components/product-mockup-styles.vue")
  },
  {
    name: "product-composables-use-product-api",
    path: "/product/composables/use-product-api",
    component: () => import("/app/apps/nuxt-front/pages/product/composables/use-product-api.ts")
  },
  {
    name: "product-page",
    path: "/product/page",
    component: () => import("/app/apps/nuxt-front/pages/product/page.vue")
  },
  {
    name: "products-components-category-link",
    path: "/products/components/category-link",
    component: () => import("/app/apps/nuxt-front/pages/products/components/category-link.vue")
  },
  {
    name: "products-composables-use-categories-api",
    path: "/products/composables/use-categories-api",
    component: () => import("/app/apps/nuxt-front/pages/products/composables/use-categories-api.ts")
  },
  {
    name: "products-composables-use-products-api",
    path: "/products/composables/use-products-api",
    component: () => import("/app/apps/nuxt-front/pages/products/composables/use-products-api.ts")
  },
  {
    name: "products-page",
    path: "/products/page",
    component: () => import("/app/apps/nuxt-front/pages/products/page.vue")
  },
  {
    name: "registration-components-registration-stages",
    path: "/registration/components/registration-stages",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/registration-stages.vue")
  },
  {
    name: "registration-components-stage-indicator",
    path: "/registration/components/stage-indicator",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/stage-indicator.vue")
  },
  {
    name: "registration-components-stages-company-registration-stage",
    path: "/registration/components/stages/company-registration-stage",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/stages/company-registration-stage.vue")
  },
  {
    name: "registration-components-stages-profile-registration-stage",
    path: "/registration/components/stages/profile-registration-stage",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/stages/profile-registration-stage.vue")
  },
  {
    name: "registration-composables-use-registration-validation",
    path: "/registration/composables/use-registration-validation",
    component: () => import("/app/apps/nuxt-front/pages/registration/composables/use-registration-validation.ts")
  },
  {
    name: "registration-page",
    path: "/registration/page",
    component: () => import("/app/apps/nuxt-front/pages/registration/page.vue")
  }
]