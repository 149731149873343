import revive_payload_client_QKls0EN9ZX from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YGCCi7c1g8 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mJkVZ9h65K from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_mwrGXku1BR from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_TVFvbMzEP4 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_feHwZFLlc3 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NPsdSrpQ0V from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_rQOQI6GhKY from "/app/apps/nuxt-front/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt-front/.nuxt/components.plugin.mjs";
import prefetch_client_9omiFtRdaC from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_upfdnm4g4uwfngwctvdgf5froi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/apps/nuxt-front/.nuxt/primevue-plugin.mjs";
import plugin_client_wAzm1fdi8P from "/app/apps/nuxt-front/node_modules/.pnpm/@primevue+nuxt-module@4.0.7_@babel+parser@7.25.6_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_webpack-sources@3.2.3/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/app/apps/nuxt-front/.nuxt/unocss.mjs";
import maska_UHaKf2z1iQ from "/app/apps/nuxt-front/plugins/maska.ts";
export default [
  revive_payload_client_QKls0EN9ZX,
  unhead_YGCCi7c1g8,
  router_mJkVZ9h65K,
  payload_client_mwrGXku1BR,
  navigation_repaint_client_TVFvbMzEP4,
  check_outdated_build_client_feHwZFLlc3,
  chunk_reload_client_NPsdSrpQ0V,
  plugin_vue3_rQOQI6GhKY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9omiFtRdaC,
  primevue_plugin_egKpok8Auk,
  plugin_client_wAzm1fdi8P,
  unocss_MzCDxu9LMj,
  maska_UHaKf2z1iQ
]