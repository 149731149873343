import type { RouterConfig } from '@nuxt/schema';

export default <RouterConfig> {
  routes: _routes => [
    {
      name: 'home',
      path: '/:orderId?',
      component: () => import('~/pages/orders/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
        name: 'Orders',
        key: 'orders',
      },
    },

    {
      name: 'billing-update-methods',
      path: '/billing/update-methods',
      component: () => import('~/pages/billing/update-methods/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },

    {
      name: 'products',
      path: '/products',
      component: () => import('@/pages/products/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },

    {
      name: 'product',
      path: '/products/:id',
      component: () => import('@/pages/product/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },

    {
      name: 'registration',
      path: '/register',
      component: () => import('@/pages/registration/page.vue'),
      meta: {
        middleware: 'unauthenticated',
        layout: 'unauthenticated',
      },
    },

    {
      name: 'login',
      path: '/login',
      component: () => import('@/pages/login/page.vue'),
      meta: {
        middleware: 'unauthenticated',
        layout: 'unauthenticated',
      },
    },
    {
      path: '/:pathMatch(.*)*', // Catch-all route
      name: 'not-found',
      component: () => import('@/pages/not-found/page.vue'),
      meta: {
        layout: 'unauthenticated',
      },
    },
  ],
};
